.event_img:hover {
  margin: "10%";
  cursor: pointer;
  height: 400px;
  width: "auto";
}

/* --------------- event-img-views ---------------- */

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 100%;
  -moz-column-width: 33%;
  column-width: 33%;
  padding: 0 12px;
}

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: zoom-in;
  margin-bottom: 12px;
  border-radius: 5px;
}

.gallery .pics:hover {
  filter: opacity(0.8);
}

/* ----------------- events images /model part ---------------- */

.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  cursor: zoom-out;
}

.open {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 1%;
  left: 0;
  bottom: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0000;
  transition: opacity 0.8s ease, visibility 0.4s ease,
    transform 0.6s ease-in-out;
  transform: scale(0);
  overflow: hidden;
  z-index: 999;
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

.close_icon {
  position: fixed;
  top: 6%;
  right: 6%;
  cursor: pointer;
  border-radius: 10;
  align-items: center;
  color: #cd0808;
}

.close_icon:hover {
  background-color: rgb(3, 14, 34);
}

.model_img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
  cursor: pointer;
}

/* --------------------- media for event images ---------------- */

@media (max-width: 912px) {
  .gallery {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%;
    padding: 0 12px;
  }
  .close_icon {
    position: fixed;
    top: 30%;
    right: 4%;
    cursor: pointer;
    border-radius: 10;
    align-items: center;
    color: #cd0808;
  }
}

/* ----------- footer ----------- */
